import {feature} from '@admin-tribe/acsc';
import {generatePath} from 'react-router-dom';

import rootStore from 'core/RootStore';
import {canViewAccountOverviewPage} from 'core/account/access/accountAccess';
import {buildDefaultSectionRedirectLoader} from 'core/router/loaders';
import {
  PATH_ACCOUNT_CHANGE_PLAN,
  PATH_ACCOUNT_CHANGE_SPECIFIC_PLAN,
  PATH_ACCOUNT_OVERVIEW,
  PATH_ACCOUNT_ROOT,
} from 'features/account/routing/accountPaths';
import {PATH_APPROVE_PRODUCT} from 'features/approve-product/routing/productApprovePaths';
import {PATH_INSIGHTS_ROOT} from 'features/insights/routing/insightsPaths';
import {
  PATH_OVERVIEW,
  PATH_OVERVIEW_ADD_PRODUCTS,
  PATH_OVERVIEW_ASSIGN_PRODUCTS,
  PATH_OVERVIEW_QUICK_ASSIGN_PRODUCTS,
  PATH_OVERVIEW_REVIEW_SAVED_USER_INTRODUCTIONS,
  PATH_OVERVIEW_REVIEW_USER_INTRODUCTIONS,
} from 'features/overview/routing/overviewPaths';
import {PATH_ALL_PACKAGES} from 'features/packages/routing/packagesPaths';
import {
  PATH_ADD_PRODUCTS,
  PATH_ASSIGN_PRODUCTS,
  PATH_PRODUCTS,
  PATH_PRODUCTS_AUTO_ASSIGN,
  PATH_PRODUCTS_REQUESTS,
} from 'features/products/routing/productsPaths';
import {
  PATH_SETTINGS_IDENTITY_DIRECTORIES,
  PATH_SETTINGS_ROOT,
} from 'features/settings/routing/settingsPaths';
import {PATH_STORAGE_ROOT} from 'features/storage/routing/storagePaths';
import {PATH_SUPPORT} from 'features/support/routing/supportPaths';
import {
  PATH_DEVELOPERS,
  PATH_USERS,
  PATH_USERS_ADD_USERS_TO_ORG,
  PATH_USERS_EDIT_PRODUCTS_AND_USER_GROUPS_SPECIFIC,
  PATH_USERS_REVIEW_USER_INTRODUCTIONS,
} from 'features/users/routing/usersPaths';

const buildSiloRedirect = (from, toPath) => ({
  loader: buildDefaultSectionRedirectLoader(
    `/${from}`,
    generatePath(toPath, {orgId: rootStore.organizationStore.activeOrgId})
  ),
  path: from,
});

const getApproveProductRedirect = () => ({
  loader: buildDefaultSectionRedirectLoader(
    '/approve-product',
    generatePath(PATH_APPROVE_PRODUCT, {orgId: rootStore.organizationStore.activeOrgId})
  ),
  path: 'approve-product',
});

const getProductsRedirects = () => ({
  children: [
    {
      element: null,
      loader: buildDefaultSectionRedirectLoader(
        '/products/add-products',
        generatePath(PATH_ADD_PRODUCTS, {orgId: rootStore.organizationStore.activeOrgId}),
        {withQueryParams: true}
      ),
      path: 'add-products',
    },
    {
      element: null,
      loader: buildDefaultSectionRedirectLoader(
        '/products/assign-products',
        generatePath(PATH_ASSIGN_PRODUCTS, {orgId: rootStore.organizationStore.activeOrgId}),
        {withQueryParams: true}
      ),
      path: 'assign-products',
    },
    {
      element: null,
      loader: buildDefaultSectionRedirectLoader(
        '/products/auto-assign',
        generatePath(PATH_PRODUCTS_AUTO_ASSIGN, {orgId: rootStore.organizationStore.activeOrgId}),
        {withQueryParams: true}
      ),
      path: 'auto-assign',
    },
    {
      element: null,
      loader: buildDefaultSectionRedirectLoader(
        '/products/requests',
        generatePath(PATH_PRODUCTS_REQUESTS, {orgId: rootStore.organizationStore.activeOrgId}),
        {withQueryParams: true}
      ),
      path: 'requests',
    },
  ],
  loader: buildDefaultSectionRedirectLoader(
    '/products',
    generatePath(PATH_PRODUCTS, {orgId: rootStore.organizationStore.activeOrgId})
  ),
  path: 'products',
});

const getOverviewRedirects = () => {
  const children = [
    {
      element: null,
      loader: buildDefaultSectionRedirectLoader(
        '/overview/add-products',
        generatePath(PATH_OVERVIEW_ADD_PRODUCTS, {orgId: rootStore.organizationStore.activeOrgId}),
        {withQueryParams: true}
      ),
      path: 'add-products',
    },
    // flag this
    {
      element: null,
      loader: buildDefaultSectionRedirectLoader(
        '/overview/assign-products',
        generatePath(PATH_OVERVIEW_ASSIGN_PRODUCTS, {
          orgId: rootStore.organizationStore.activeOrgId,
        }),
        {withQueryParams: true}
      ),
      path: 'assign-products',
    },
    {
      element: null,
      loader: buildDefaultSectionRedirectLoader(
        '/overview/quick-assign-products',
        generatePath(PATH_OVERVIEW_QUICK_ASSIGN_PRODUCTS, {
          orgId: rootStore.organizationStore.activeOrgId,
        }),
        {withQueryParams: true}
      ),
      path: 'quick-assign-products',
    },
  ];
  if (feature.isEnabled('temp_introductions_modal_deeplink')) {
    children.push({
      element: null,
      loader: buildDefaultSectionRedirectLoader(
        '/overview/review-user-introductions',
        generatePath(PATH_OVERVIEW_REVIEW_USER_INTRODUCTIONS, {
          orgId: rootStore.organizationStore.activeOrgId,
        }),
        {withQueryParams: true}
      ),
      path: 'review-user-introductions',
    });
    children.push({
      loader: buildDefaultSectionRedirectLoader(
        '/users/review-user-introductions',
        generatePath(PATH_USERS_REVIEW_USER_INTRODUCTIONS, {
          orgId: rootStore.organizationStore.activeOrgId,
        }),
        {withQueryParams: true}
      ),
      path: 'users/review-user-introductions',
    });
  }
  if (feature.isEnabled('temp_saved_introductions_modal_deeplink')) {
    children.push({
      element: null,
      loader: buildDefaultSectionRedirectLoader(
        '/overview/review-saved-user-introductions',
        generatePath(PATH_OVERVIEW_REVIEW_SAVED_USER_INTRODUCTIONS, {
          orgId: rootStore.organizationStore.activeOrgId,
        }),
        {withQueryParams: true}
      ),
      path: 'review-saved-user-introductions',
    });
  }
  return {
    children,
    loader: buildDefaultSectionRedirectLoader(
      '/overview',
      generatePath(PATH_OVERVIEW, {orgId: rootStore.organizationStore.activeOrgId})
    ),
    path: 'overview',
  };
};

const getSettingsRedirects = () => ({
  children: [
    {
      element: null,
      loader: buildDefaultSectionRedirectLoader(
        '/settings/identity/directories',
        generatePath(PATH_SETTINGS_IDENTITY_DIRECTORIES, {
          orgId: rootStore.organizationStore.activeOrgId,
        }),
        {withQueryParams: true}
      ),
      path: '/settings/identity/directories',
    },
  ],
  loader: buildDefaultSectionRedirectLoader(
    '/settings',
    generatePath(PATH_SETTINGS_ROOT, {orgId: rootStore.organizationStore.activeOrgId})
  ),
  path: 'settings',
});

const buildEditUsersProductsSpecificProductRedirect = () => {
  let memberId;
  if (typeof window !== 'undefined') {
    const pathSegments = window.location.pathname.split('/');
    memberId = pathSegments[pathSegments.length - 1];
  }
  const toPath = generatePath(PATH_USERS_EDIT_PRODUCTS_AND_USER_GROUPS_SPECIFIC, {
    memberId,
    orgId: rootStore.organizationStore.activeOrgId,
  });

  return {
    loader: buildDefaultSectionRedirectLoader(
      'users/edit-products-and-user-groups/:memberId',
      toPath,
      {withQueryParams: true}
    ),
    path: 'users/edit-products-and-user-groups/:memberId',
  };
};

const buildChangePlanSpecificProductRedirect = () => {
  let productIdentifier;
  if (typeof window !== 'undefined') {
    const pathSegments = window.location.pathname.split('/');
    productIdentifier = pathSegments[pathSegments.length - 1];
  }

  return {
    loader: buildDefaultSectionRedirectLoader(
      '/account/change-plan/:productIdentifier',
      generatePath(PATH_ACCOUNT_CHANGE_SPECIFIC_PLAN, {
        orgId: rootStore.organizationStore.activeOrgId,
        productIdentifier,
      }),
      {withQueryParams: true}
    ),
    path: 'account/change-plan/:productIdentifier',
  };
};

const buildAddUsersToOrgRedirects = () => {
  const toPath = generatePath(PATH_USERS_ADD_USERS_TO_ORG, {
    orgId: rootStore.organizationStore.activeOrgId,
  });
  return {
    loader: buildDefaultSectionRedirectLoader('/users/add-users-to-org', toPath, {
      withQueryParams: true,
    }),
    path: 'users/add-users-to-org',
  };
};

const buildReviewUsersIntroductionsToOrgRedirects = () => {
  const toPath = generatePath(PATH_USERS_REVIEW_USER_INTRODUCTIONS, {
    orgId: rootStore.organizationStore.activeOrgId,
  });
  return {
    loader: buildDefaultSectionRedirectLoader('/users/review-user-introductions', toPath, {
      withQueryParams: true,
    }),
    path: 'users/review-user-introductions',
  };
};

/**
 * The top-level routes that will redirect to a subroute beneath the org ID.
 * Ex /users -> /:orgId/users
 */
const getSiloRoutes = () => [
  buildSiloRedirect(
    'account',
    canViewAccountOverviewPage() ? PATH_ACCOUNT_OVERVIEW : PATH_ACCOUNT_ROOT
  ),
  buildSiloRedirect('account/change-plan', PATH_ACCOUNT_CHANGE_PLAN),
  buildAddUsersToOrgRedirects(),
  buildReviewUsersIntroductionsToOrgRedirects(),
  buildChangePlanSpecificProductRedirect(),
  buildEditUsersProductsSpecificProductRedirect(),
  buildSiloRedirect('developer', PATH_DEVELOPERS),
  buildSiloRedirect('insights', PATH_INSIGHTS_ROOT),
  getApproveProductRedirect(),
  getOverviewRedirects(),
  buildSiloRedirect('packages', PATH_ALL_PACKAGES),
  getProductsRedirects(),
  getSettingsRedirects(),
  buildSiloRedirect('storage', PATH_STORAGE_ROOT),
  buildSiloRedirect('support', PATH_SUPPORT),
  buildSiloRedirect('users', PATH_USERS),
];

export default getSiloRoutes;
