/* eslint-disable max-lines -- this file requires more lines */
import {
  CONTRACT_MIGRATION_TYPE,
  LIST_DEFAULT_PAGE_SIZE,
  Locale,
  PRODUCT_LIST_PI_CODES,
  ProductList,
  feature,
  getOfferSwitchMigrationEligibleContract,
  getProductsByOfferIds,
} from '@admin-tribe/acsc';
import {getProductDisplayName} from '@admin-tribe/acsc-ui';
import mapValues from 'lodash/mapValues';
import uniqBy from 'lodash/uniqBy';

import {logLicenseDataPIMissing} from 'common/utils/products-utils/productProcessingInstructions';
import rootStore from 'core/RootStore';
import ProductUserList from 'core/services/product/ProductUserList';
import OrganizationContractMigrationList from 'features/contract/models/OrganizationContractMigrationList';
import {NO_ASSIGNMENT_ID} from 'features/products/product-migration-table/ProductMigrationTable.constants';
import {
  MAX_MIGRATION_NUMBER,
  PRODUCT_TYPES_FOR_CONTRACT_MIGRATION,
} from 'features/users/components/offer-switch-migration/OfferSwitchMigrationConstants';

/**
 * @description Get offer switch contract migration for the current org.
 *
 * @returns {Promise} Resolves with {contract, contractMigrationList, isEligible} if offer switch migration exists.
 * Otherwise, resolves to empty object.
 */
const getOfferSwitchMigration = async () => {
  const contractList = rootStore.organizationStore.contractList;
  const contract = getOfferSwitchMigrationEligibleContract(contractList);
  const productList = rootStore.organizationStore.productList;

  if (!contract) {
    return {isEligible: false};
  }

  const contractMigrationList = await OrganizationContractMigrationList.get({
    contractId: contract.id,
    type: CONTRACT_MIGRATION_TYPE.OFFER_SWITCH,
  });

  return {
    contract,
    contractMigrationList,
    isEligible: checkMigrationProductExists({contractMigrationList, productList}),
  };
};

/**
 * @description Get source/target products from an offer switch migration.
 *
 * @param {OrganizationContractMigrationList} contractMigrationList Offer switch contract migration,
 *  assumes that the call to retrieve the contract migrations list is already resolved
 * @param {ProductList} productList Product list to get product from offer id.
 * Note: This param can be removed when OrganizationManager is moved to src2
 * @param {String} type Product type. source or target
 *
 * @returns {Array<Product>} List of source/target products
 */
const getProductsByContractMigrationList = ({contractMigrationList, productList, type}) => {
  const offerIds = getOfferIdsByContractMigrationList({contractMigrationList, type});
  return getProductsByOfferIds(productList, offerIds);
};

/**
 * @description Get eligible migrations data
 *
 * @param {Object} intl react intl
 *
 * @returns {Promise} Resolve with a list of migration object.
 * Ex: [{id: migrationId, soruce: sourceProduct, targets: [targetProduct], user: OrganizationUser}]
 */
const getEligibleMigrations = async (intl) => {
  const {contractMigrationList} = await getOfferSwitchMigration();
  // one source product could map to multiple target products
  const contractMigrationProducts = contractMigrationList.items.flatMap((contractMigration) =>
    contractMigration.migrationContext.products.reduce((migrationProducts, {source, target}) => {
      const index = migrationProducts.findIndex(
        (product) => source.offerId === product.source.offerId
      );
      if (index === -1) {
        migrationProducts.push({source, targets: [target]});
      } else {
        migrationProducts[index].targets.push(target);
      }
      return migrationProducts;
    }, [])
  );

  const eligibleMigrations = await getEligibleMigrationsByMigrationProducts({
    contractMigrationList,
    contractMigrationProducts,
    intl,
  });
  setDefaultSelectedTargetId(eligibleMigrations);

  return eligibleMigrations;
};

/**
 * @description Return an object which contains migration summary data
 *
 * @param {Array<Object>} eligibleMigrations Eligible migrations
 *
 * @returns {Object} An object containing lists for sourceProducts, targetProducts, usersToGetAccess and usersToLoseAccess
 */
const getMigrationSummaryData = (eligibleMigrations) => {
  const language = Locale.get().activeLanguageBCP47Code;
  const summary = {
    sourceProducts: [],
    targetProducts: [],
    usersToGetAccess: [],
    usersToLoseAccess: [],
  };

  eligibleMigrations.forEach((migration) => {
    if (migration.selectedTargetId && migration.selectedTargetId !== 'NO_ASSIGNMENT') {
      summary.usersToGetAccess.push(migration.user);
      summary.targetProducts.push(
        migration.targets.find((target) => target.id === migration.selectedTargetId)
      );
    } else {
      summary.sourceProducts.push(migration.source);
      summary.usersToLoseAccess.push(migration.user);
    }
  });

  summary.usersToLoseAccess.sort((a, b) =>
    a.getDisplayNameOrEmail().localeCompare(b.getDisplayNameOrEmail(), language)
  );

  // each array should only contain unique object
  return mapValues(summary, (value) => uniqBy(value, 'id'));
};

/**
 * @description Get offer switch contract migration summary for the current org.
 *
 * @param {Object} intl react intl
 *
 * @returns {Promise} Resolves with an array of offer switch migration summary.
 * Ex: [{productList: [count: 5, displayName: 'Photoshop', iconUrl: 'url', id: 'id'], title: 'NEW LICENSES'}]
 */
const getOfferSwitchMigrationSummary = async (intl) => {
  const {contractMigrationList} = await getOfferSwitchMigration();
  const productList = rootStore.organizationStore.productList;

  return Object.values(PRODUCT_TYPES_FOR_CONTRACT_MIGRATION).map((type) => ({
    productList: getProductListByContractMigrationList({
      contractMigrationList,
      intl,
      productList,
      type,
    }),
    title: intl.formatMessage({
      id: `users.offerSwitchMigrationIntro.summary.title.${type}`,
    }),
  }));
};

/**
 * @description Return request body for delegates migration api.
 *
 * @param {Array<Object>} eligibleMigrations Eligible migrations
 *
 * @returns {Array<Object>} Request body for delegates migration api.
 * Ex: [{destinationLicenseId: 'lid', destinationOfferId: 'oid', licenseGroupId: '123', sourceContractId: 'cid',
 *  sourceOfferId: 'sid', sourceLicenseId: 's-lid', users: [{id: 'userId'}]}]
 */
const getRequestBodyByEligibleMigrations = (eligibleMigrations) => {
  const idToRequestBodyMap = {}; // we need this map to track if the source and target pair is already added
  eligibleMigrations.forEach(({selectedTargetId, source, targets, user}) => {
    if (selectedTargetId && selectedTargetId !== NO_ASSIGNMENT_ID) {
      const selectedTarget = targets.find((target) => target.id === selectedTargetId);
      const id = source.id + selectedTargetId;

      if (idToRequestBodyMap[id]) {
        idToRequestBodyMap[id].users.push({id: user.id});
      } else {
        idToRequestBodyMap[id] = {
          destinationLicenseId: selectedTarget.licenseId,
          destinationOfferId: selectedTargetId,
          licenseGroupId: selectedTarget.licenseGroupId,
          sourceContractId: source.contractId,
          sourceLicenseId: source.licenseId,
          sourceOfferId: source.id,
          users: [{id: user.id}],
        };
      }
    }
  });

  return Object.values(idToRequestBodyMap);
};

/* Private methods */

async function getEligibleMigrationsByMigrationProducts({
  contractMigrationList,
  contractMigrationProducts,
  intl,
}) {
  // Need 1 more than the max eligible migration to differentiate between wizard vs bulk op
  const PRODUCT_USER_LIST_PAGE_SIZE = MAX_MIGRATION_NUMBER + 1;

  const productList = await ProductList.get({
    // we need license group id from licenseGroupSummaries in order to migrate user
    licenseGroupLimit: LIST_DEFAULT_PAGE_SIZE,
    notifyListeners: false,
    orgId: rootStore.organizationStore.activeOrgId,
    ...(feature.isEnabled('temp_enable_pi_codes') && {
      processingInstructionCodes: PRODUCT_LIST_PI_CODES,
    }),
  });
  logLicenseDataPIMissing(productList, rootStore.organizationStore.activeOrgId);

  // use this map so different source/user pairs can point to the same target product object
  // by doing this, we only need to update target product once if it needs to change value when user select it
  const targetProductMap = getProductMap({
    contractMigrationList,
    intl,
    productList,
    type: PRODUCT_TYPES_FOR_CONTRACT_MIGRATION.TARGET,
  });

  const eligibleMigrationList = await Promise.all(
    contractMigrationProducts.map(async ({source, targets}) => {
      const sourceProduct = getProductsByOfferIds(productList, [source?.offerId])[0];
      const targetProducts = getProductsByOfferIds(
        productList,
        targets.map((target) => target.offerId)
      );

      const sourceProductUserList = await ProductUserList.get({
        orgId: rootStore.organizationStore.activeOrgId,
        pageSize: PRODUCT_USER_LIST_PAGE_SIZE,
        productId: sourceProduct?.id,
      });
      const targetProductUserPairs = await Promise.all(
        targetProducts.map(async (targetProduct) => {
          const targetProductUserList = await ProductUserList.get({
            orgId: rootStore.organizationStore.activeOrgId,
            pageSize: PRODUCT_USER_LIST_PAGE_SIZE,
            productId: targetProduct.id,
          });
          return {targetProduct, targetProductUserList};
        })
      );

      return getMigrationData({
        intl,
        sourceProduct,
        sourceProductUserList,
        targetProductMap,
        targetProductUserPairs,
      });
    })
  );

  // each contract migration product will return an array of migration data so we should flat it.
  return eligibleMigrationList.flat();
}

function checkMigrationProductExists({contractMigrationList, productList}) {
  const offerIds = Object.values(PRODUCT_TYPES_FOR_CONTRACT_MIGRATION).flatMap((type) =>
    getOfferIdsByContractMigrationList({contractMigrationList, type})
  );

  if (offerIds.length > 0) {
    const products = getProductsByOfferIds(productList, offerIds);
    return offerIds.length === products.length;
  }
  return false; // Bad data, assume product doesn't exist
}

function getCountByProductType({product, shouldShowAvailableTargetCount, type}) {
  return type === PRODUCT_TYPES_FOR_CONTRACT_MIGRATION.SOURCE
    ? product.getAssignableLicenseCount()
    : product.getAssignableLicenseCount() -
        ((shouldShowAvailableTargetCount && product.delegatedQuantity) || 0);
}

function getMigrationData({
  intl,
  sourceProduct,
  sourceProductUserList,
  targetProductMap,
  targetProductUserPairs,
}) {
  return sourceProductUserList.items.reduce((eligibleMigrations, user) => {
    const targets = targetProductUserPairs.reduce(
      (targetProducts, {targetProduct, targetProductUserList}) => {
        // check if user is already migrated to the target product
        if (!targetProductUserList.items.find((targetUser) => targetUser.id === user.id)) {
          targetProducts.push(targetProductMap[targetProduct.offerId]);
        }
        return targetProducts;
      },
      []
    );

    // only include the migration data when there is available target product
    if (targets.length > 0) {
      eligibleMigrations.push({
        id: `${user.id}-${sourceProduct.offerId}`,
        source: getTrimmedProduct({
          intl,
          product: sourceProduct,
          type: PRODUCT_TYPES_FOR_CONTRACT_MIGRATION.SOURCE,
        }),
        targets,
        user,
      });
    }

    return eligibleMigrations;
  }, []);
}

function getOfferIdsByContractMigration({contractMigration, type}) {
  return contractMigration.migrationContext.products.reduce((offerIds, product) => {
    const offerId = product[type]?.offerId;
    // make offerIds unique and compact
    if (!!offerId && !offerIds.includes(offerId)) {
      offerIds.push(offerId);
    }
    return offerIds;
  }, []);
}

function getOfferIdsByContractMigrationList({contractMigrationList, type}) {
  return contractMigrationList?.items.flatMap((contractMigration) =>
    getOfferIdsByContractMigration({contractMigration, type})
  );
}

function getProductListByContractMigrationList({contractMigrationList, intl, productList, type}) {
  const products = getProductsByContractMigrationList({contractMigrationList, productList, type});

  return products.map((product) => getTrimmedProduct({intl, product, type}));
}

function getProductMap({contractMigrationList, intl, productList, type}) {
  const productMap = {};

  getProductsByContractMigrationList({
    contractMigrationList,
    productList,
    type,
  }).forEach((product) => {
    productMap[product.offerId] = getTrimmedProduct({
      intl,
      product,
      shouldShowAvailableTargetCount: true,
      type,
    });
  });
  return productMap;
}

function getTargetIdToUserIdsMap(eligibleMigrations) {
  const targetIdToUserIds = {};
  eligibleMigrations.forEach((migration) => {
    const firstTargetId = migration.targets[0].id;
    if (
      migration.targets.length === 1 &&
      // user should only be migrated to a target product once
      !targetIdToUserIds[firstTargetId]?.includes(migration.user.id)
    ) {
      targetIdToUserIds[firstTargetId] = targetIdToUserIds[firstTargetId] || [];
      targetIdToUserIds[firstTargetId].push(migration.user.id);
    }
  });
  return targetIdToUserIds;
}

function getTrimmedProduct({intl, product, shouldShowAvailableTargetCount, type}) {
  return {
    contractId: product.contractId,
    count: getCountByProductType({product, shouldShowAvailableTargetCount, type}),
    displayName: getProductDisplayName(intl, product),
    iconUrl: product.getIcon(),
    id: product.offerId,
    licenseGroupId: product.licenseGroupSummaries?.[0]?.id,
    licenseId: product.id,
  };
}

function setDefaultSelectedTargetId(eligibleMigrations) {
  const targetIdToUserIds = getTargetIdToUserIdsMap(eligibleMigrations);

  eligibleMigrations.forEach((migration) => {
    const firstTarget = migration.targets[0];
    // set default selected target id if there is only one available target product and has enough count
    if (
      migration.targets.length === 1 &&
      firstTarget.count > 0 &&
      targetIdToUserIds[firstTarget.id]?.length > 0 &&
      targetIdToUserIds[firstTarget.id]?.length <= firstTarget.count
    ) {
      migration.selectedTargetId = firstTarget.id;
      firstTarget.count -= 1;
      targetIdToUserIds[firstTarget.id].shift(); // remove user from the list once the selceted target is added
    }
  });
}

export {
  getEligibleMigrations,
  getMigrationSummaryData,
  getOfferSwitchMigration,
  getOfferSwitchMigrationSummary,
  getProductsByContractMigrationList,
  getRequestBodyByEligibleMigrations,
};
/* eslint-enable max-lines -- this file requires more lines */
