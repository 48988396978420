import {feature, log} from '@admin-tribe/acsc';

/**
 * Checks if a product has the required processing instructions
 * @param {Object} product - The product to check
 * @returns {boolean} - Whether the product has required processing instructions
 */
const hasRequiredPI = (product) =>
  !!product?.processingInstructions &&
  !!product.processingInstructions.license_data &&
  !!product.processingInstructions.license_data.delegation_targets;

/**
 * Logs a error if license_data processing instructions are not returned for a product.
 * @param {Product | ProductList} productData - The product or list of products to check.
 * @param {string} orgId - The ID of the organization.
 */
const logLicenseDataPIMissing = (productData, orgId) => {
  try {
    // Return if feature is not enabled
    if (!feature.isEnabled('temp_enable_pi_codes')) {
      return;
    }

    // Handle undefined/null case
    if (!productData) {
      return;
    }

    // Handle single product case where we call Product.get()
    if (!productData.items) {
      if (!hasRequiredPI(productData)) {
        log.error(`license_data PI Missing for product ${productData.id} in org ${orgId}`);
      }
      return;
    }

    // Handle multiple products case where we call ProductList.get()
    const productsWithoutPI = productData.items.filter((product) => !hasRequiredPI(product));

    if (productsWithoutPI.length > 0) {
      const productIds = productsWithoutPI.map((product) => product.id).join(', ');
      log.error(`license_data PI Missing for products ${productIds} in org ${orgId}`);
    }
  } catch (error) {
    log.error('Error in logLicenseDataPIMissing:', error);
  }
};

// eslint-disable-next-line import/prefer-default-export -- utils, in future we need to add more functions if required
export {logLicenseDataPIMissing};
