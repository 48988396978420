import {dispatchUiEventAnalytics, feature} from '@admin-tribe/acsc';
import {ActionButton, Flex, Text, View} from '@adobe/react-spectrum';
import {useIntroductionList} from '@pandora/data-model-acrs';
import {INTRODUCTION_STATUS} from '@pandora/react-data-source-acrs';
import {ProductRequestSettingsOperations} from '@pandora/react-product-request-settings-modal';
import UserAddIcon from '@spectrum-icons/workflow/UserAdd';
import React, {useEffect, useState} from 'react';
import {useIntl} from 'react-intl';

import useIntroductionsStats from 'common/hooks/useIntroductionsStats';
import rootStore from 'core/RootStore';
import ReviewUserIntroductionsModalWrapper from 'features/overview/components/review-user-introductions-modal-wrapper/ReviewUserIntroductionsModalWrapper';
import useAccessRequestPolicies from 'features/products/hooks/useAccessRequestPolicies';

const formatNotificationMessages = (
  intl,
  pendingUserIntroductionsCount,
  userIntroductionsCount,
  introductionsData
) => ({
  priorityNotificationMessage: intl.formatMessage(
    {id: 'overview.users.pod.userIntroductionsPriorityNotification'},
    {
      count: intl.formatNumber(introductionsData?.actionablePriorityIntroductionsCount ?? 0),
    }
  ),
  userIntroductionNotificationMessage:
    pendingUserIntroductionsCount > 0
      ? intl.formatMessage(
          {id: 'overview.users.pod.userIntroductionsNotification'},
          {
            count: intl.formatNumber(userIntroductionsCount),
            strong: (str) => <strong>{str}</strong>,
          }
        )
      : intl.formatMessage(
          {id: 'overview.users.pod.userIntroductionsNoCountNotification'},
          {
            count: intl.formatNumber(userIntroductionsCount),
          }
        ),
  userIntroductionShortNotificationMessage:
    pendingUserIntroductionsCount > 0
      ? intl.formatMessage(
          {id: 'overview.users.pod.userIntroductionsShortNotification'},
          {
            count: intl.formatNumber(userIntroductionsCount),
            strong: (str) => <strong>{str}</strong>,
          }
        )
      : intl.formatMessage(
          {id: 'overview.users.pod.userIntroductionsNoCountShortNotification'},
          {
            count: intl.formatNumber(userIntroductionsCount),
          }
        ),
});

/**
 * @description Component for notifying the user there are users to review with matching domains.
 */
const UserIntroductionsNotification = () => {
  const intl = useIntl();
  const introductionList = useIntroductionList({
    orgId: rootStore.organizationStore.activeOrgId,
  });
  const {policies: orgPolicies} = useAccessRequestPolicies();
  const {data: introductionsData} = useIntroductionsStats();
  const [userIntroductionsCount, setUserIntroductionsCount] = useState(undefined);
  const [pendingUserIntroductionsCount, setPendingUserIntroductionsCount] = useState(undefined);
  const [isActionablePriorityNotifications, setIsActionablePriorityNotifications] = useState(false);

  // Set the count that will determine whether the domain matched user
  // notification should appear and which notification message to show
  useEffect(() => {
    if (
      introductionList?.data?.resources?.items?.length > 0 &&
      orgPolicies?.find?.(
        (policy) => policy.name === ProductRequestSettingsOperations.DOMAIN_MATCHING_ENABLED
      )?.value
    ) {
      const reviewPendingCount = introductionList.data.resources.items.filter(
        (introduction) => introduction.status === INTRODUCTION_STATUS.ADMIN_REVIEW_PENDING
      ).length;
      setPendingUserIntroductionsCount(reviewPendingCount);
      const reviewIgnoredCount = introductionList.data.resources.items.filter(
        (introduction) => introduction.status === INTRODUCTION_STATUS.ADMIN_IGNORED
      ).length;
      setUserIntroductionsCount(reviewPendingCount + reviewIgnoredCount);
    }
  }, [introductionList, orgPolicies]);

  // Set the flag that determines whether the priority notification should
  // appear and which notification message to show
  useEffect(() => {
    if (
      feature.isEnabled('temp_tno_priority_introductions') &&
      introductionsData?.actionablePriorityIntroductionsCount > 0
    ) {
      setIsActionablePriorityNotifications(true);
    }
  }, [introductionsData]);

  const [showReviewUserIntroductionsModal, setShowUserIntroductionsModal] = useState(false);
  const onShowReviewUserIntroductionsModal = () => {
    setShowUserIntroductionsModal(true);
    dispatchUiEventAnalytics({
      eventAction: 'click',
      eventName: 'userintro:notification',
    });
  };

  const onCancel = async () => {
    setShowUserIntroductionsModal(false);
    await introductionList.refresh();
  };

  const {
    userIntroductionNotificationMessage,
    userIntroductionShortNotificationMessage,
    priorityNotificationMessage,
  } = formatNotificationMessages(
    intl,
    pendingUserIntroductionsCount,
    userIntroductionsCount,
    introductionsData
  );

  // domain-matched-user-notification-message

  const generateActionButton = (message, testId, needsReview = false) => (
    <ActionButton
      height="100%"
      onPress={onShowReviewUserIntroductionsModal}
      staticColor="white"
      UNSAFE_style={{cursor: 'pointer'}}
      width="100%"
    >
      <Flex alignItems="center" marginY="size-100" width="100%" wrap="nowrap">
        {!isActionablePriorityNotifications && (
          <UserAddIcon
            marginStart="size-50"
            size="S"
            UNSAFE_style={{color: 'var(--spectrum-global-color-gray-50)'}}
          />
        )}
        <Flex width="100%" wrap="wrap">
          <Text data-testid={`${testId}-message`} UNSAFE_style={{textAlign: 'left'}}>
            {message}
          </Text>
        </Flex>
        {needsReview && (
          <Flex width="100%" wrap="wrap">
            <Text
              data-testid={`${testId}-message-needs-review`}
              UNSAFE_style={{
                textAlign: 'right',
                textDecoration: 'underline',
              }}
            >
              {intl.formatMessage({id: 'overview.users.pod.needsReview'})}
            </Text>
          </Flex>
        )}
      </Flex>
    </ActionButton>
  );

  return userIntroductionsCount > 0 || isActionablePriorityNotifications ? (
    <>
      <View
        backgroundColor="cyan-800"
        borderRadius="regular"
        colorVersion={6}
        data-testid={
          isActionablePriorityNotifications
            ? 'actionable-priority-notification'
            : 'domain-matched-user-notification'
        }
        margin="size-50"
      >
        {isActionablePriorityNotifications &&
          generateActionButton(
            priorityNotificationMessage,
            'actionable-priority-notification',
            true
          )}
        {!isActionablePriorityNotifications &&
          feature.isEnabled('temp_admin_ddm_needs_review') &&
          generateActionButton(
            userIntroductionShortNotificationMessage,
            'domain-matched-user-short-notification',
            true
          )}
        {!isActionablePriorityNotifications &&
          feature.isDisabled('temp_admin_ddm_needs_review') &&
          generateActionButton(
            userIntroductionNotificationMessage,
            'domain-matched-user-notification'
          )}
      </View>
      {showReviewUserIntroductionsModal && (
        <ReviewUserIntroductionsModalWrapper onClose={onCancel} />
      )}
    </>
  ) : null;
};

export default UserIntroductionsNotification;
