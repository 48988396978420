import {PRODUCT_LIST_PI_CODES, ProductList, feature} from '@admin-tribe/acsc';
import {useAsyncModel} from '@admin-tribe/acsc-ui';
import {useCallback} from 'react';

import {logLicenseDataPIMissing} from 'common/utils/products-utils/productProcessingInstructions';

/**
 * @description A helper hook to load ProductList model with cancellation and pricing information.
 * It will also maintain the loading and the error state.
 * @param {Object} options - set up options, described below
 * @param {String} options.orgId - the Organization Id
 * @returns {Object} state - Object composed of state variables: assignedUsers, error, isLoading.
 *          {ProductList} state.productList - enhanced ProductList instance with cancellation and
 *            pricing information.
 *          {Boolean} state.isLoading - Whether data is currently being loaded.
 *          {Object} state.error - If loading data failed, then this contains the error that occurred.
 */
const useProductListForCancellation = ({orgId}) => {
  // We need to use useCallback so that React doesn't create a new function on every render cycle.
  const loadProductListForCancellation = useCallback(async () => {
    const productList = await ProductList.get({
      includeCancellationData: true,
      includePricingData: true,
      isCacheable: false,
      notifyListeners: false,
      orgId,
      ...(feature.isEnabled('temp_enable_pi_codes') && {
        processingInstructionCodes: PRODUCT_LIST_PI_CODES,
      }),
    });
    logLicenseDataPIMissing(productList, orgId);
    return productList;
  }, [orgId]);

  // Configure useAsyncModel to load the ProductUserList model
  const {model, isLoading, error} = useAsyncModel({
    loadFn: loadProductListForCancellation,
  });

  return {error, isLoading, productList: model};
};

export default useProductListForCancellation;
